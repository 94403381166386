export const defaultGoogleMapCenter = {
  lat:
    process.env.REACT_APP_Country === "Lebanon"
      ? 33.8547
      : process.env.REACT_APP_Country === "Rwanda"
      ? -1.9403
      : 7.3697,
  lng:
    process.env.REACT_APP_Country === "Lebanon"
      ? 35.5236
      : process.env.REACT_APP_Country === "Rwanda"
      ? 29.8795
      : 12.3547,
};

declare global {
  interface String {
    truncate(maxLength: number, appendSuffix?: string): string;
    capitalize(): string;
  }
}

String.prototype.truncate = function (this: string, maxLength: number, appendSuffix: string = "..."): string {
  if (this.length <= maxLength) {
    return this;
  }
  return this.substring(0, maxLength - appendSuffix.length) + appendSuffix;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faClock } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CustomTooltip from "../../../UI/CustomTooltip";

interface Props {
  receivers: Array<string>;
  futureReceivers: Array<string>;
}

export default function ReceiverCell(props: Props) {
  const { t } = useTranslation();
  const [showPotentionalReceiversTooltip, setShowPotentionalReceiversTooltip] = useState(false);
  const [showReceiversTooltip, setShowReceiversTooltip] = useState(false);

  return (
    <div className="flex flex-col justify-center">
      <div className="flex items-center">
        <label>{`${props.futureReceivers.length} ${t("recipients")}`}</label>
        <div
          className="flex cursor-pointer ml-2 p-[6px] hover:bg-gray-f2 rounded-[3px]"
          onMouseEnter={() => setShowPotentionalReceiversTooltip(true)}
          onMouseLeave={() => setShowPotentionalReceiversTooltip(false)}
        >
          <FontAwesomeIcon className="text-orange-primary" icon={faClock} />

          <CustomTooltip
            show={showPotentionalReceiversTooltip}
            position={{
              x: -70,
              y: 20,
            }}
          >
            TODO
          </CustomTooltip>
        </div>
      </div>

      <div className="flex items-center">
        <label>{`${props.receivers.length} ${t("recipients")}`}</label>
        <div
          className="flex cursor-pointer ml-2 p-[6px] hover:bg-gray-f2 rounded-[3px]"
          onMouseEnter={() => setShowReceiversTooltip(true)}
          onMouseLeave={() => setShowReceiversTooltip(false)}
        >
          <FontAwesomeIcon className="text-green-primary" icon={faCircleCheck} />

          <CustomTooltip
            show={showReceiversTooltip}
            position={{
              x: -70,
              y: 20,
            }}
          >
            TODO
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
}

import { t } from "i18next";
import MainContainer from "../../UI/MainContainer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  CIMA_PHONE,
  CimaTimezone,
  getRepeatFrequencyByValue,
  MessageObj,
  RecipientObj,
  RepeatFrequency,
} from "./MsgModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { faUsers, faUser } from "@fortawesome/pro-regular-svg-icons";
import CustomTooltip from "../../UI/CustomTooltip";
import LanguageBox from "../../UI/LanguageBox";
import TextAreaInput from "../../UI/TextAreaInput";
import TextInput from "../../UI/TextInput";
import PrimaryButton from "../../UI/PrimaryButton";
import ContactListModal from "./contacts/ContactListModal";
import ScheduleSendModal from "./ScheduleSendModal";
import DynamicContactModal from "./contacts/DynamicContactModal";
import { DynamicContactWeekRange } from "./contacts/ContactModel";
import MsgManagementAPI from "../../../store/api/MsgManagementAPI";
import { getLanguages } from "../../../LanguageManager";
import { useHistory } from "react-router-dom";

const DEFAULT_MESSAGE: MessageObj = {
  staticGroups: [],
  dynamicGroups: [],
  messageInfo: {
    firstSendDate: null,
    repeatFrequencyType: getRepeatFrequencyByValue(RepeatFrequency.NO),
    repeatFrequency: 0,
    repeatForNewContactsOnly: false,
    textMessage: {
      en: "",
      fr: "",
      rw: "",
      ar: "",
    },
  },
};

export default function AddMessage() {
  const { t } = useTranslation();
  const { sendSMS } = MsgManagementAPI();
  const history = useHistory();

  const [showContactTooltip, setShowContactTooltip] = useState(false);
  const [showDynamicContactTooltip, setShowDynamicContactTooltip] = useState(false);
  const [showContactListModal, setShowContactListModal] = useState(false);
  const [showDynamicContactModal, setShowDynamicContactModal] = useState(false);
  const [showSendTooltip, setShowSendTooltip] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");

  const [recipientArray, setRecipientArray] = useState<RecipientObj[]>([]);
  const [dynamicContactsRange, setDynamicContactsRange] = useState<DynamicContactWeekRange[]>([]);
  const [message, setMessage] = useState<MessageObj>(DEFAULT_MESSAGE);

  const [showReceipentErr, setShowReceipentErr] = useState(false);
  const [showMessageErr, setShowMessageErr] = useState(false);

  useEffect(() => {
    if (recipientArray.length > 0 || dynamicContactsRange.length > 0) {
      setShowReceipentErr(false);
    }

    setMessage({
      ...message,
      staticGroups: recipientArray.map((rec) => rec.id),
      dynamicGroups: dynamicContactsRange.map((dcr) => ({ from_range: dcr.fromWeek, to_range: dcr.toWeek })),
    });
  }, [recipientArray, dynamicContactsRange]);

  const formIsValid = () => {
    let isValid = true;

    if (message.staticGroups.length === 0 && message.dynamicGroups.length === 0) {
      setShowReceipentErr(true);
      isValid = false;
    }

    const languages = getLanguages();
    languages.forEach((lng) => {
      if (!message.messageInfo?.textMessage?.[lng]) {
        setShowMessageErr(true);

        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <MainContainer>
      <main className="alignment mb-8">
        <h3 className="text-lg text-black27 font-semibold">{t("add_health_message")}</h3>

        <section className="flex flex-col bg-white mt-4">
          <div className="flex items-center p-6 border-b-[1px]">
            <label className="text-gray-41 mr-2">{`${t("from").capitalize()}:`}</label>
            <label>{`Cima phone number ${CIMA_PHONE}`}</label>
          </div>

          <div className="flex flex-col p-6">
            <div className="flex items-center ">
              <label className="text-gray-41 mr-2">
                {`${t("to").capitalize()}`}
                <span className="text-red-primary mx-1">*</span>
                <span>:</span>
              </label>

              <div className="flex flex-1 mr-2">
                {recipientArray.map((rec, index) => {
                  return (
                    <ReceipentView
                      key={index}
                      receipent={rec}
                      onRemoveReceipent={() => {
                        setRecipientArray((prevValue) => prevValue.filter((rc) => rc.name !== rec.name));
                      }}
                    />
                  );
                })}
                {dynamicContactsRange.map((range, index) => {
                  return (
                    <ReceipentView
                      key={index}
                      range={range}
                      onRemoveReceipent={() => {
                        setDynamicContactsRange((prevValue) =>
                          prevValue.filter((rng) => rng.fromWeek !== range.fromWeek && rng.toWeek !== range.toWeek)
                        );
                      }}
                    />
                  );
                })}
                {recipientArray.length === 0 && dynamicContactsRange.length === 0 && "-"}
              </div>

              <div className="flex text-blue-primary">
                <div
                  className="mr-6 cursor-pointer p-1 bg-white hover:bg-gray-f2"
                  onMouseEnter={() => setShowContactTooltip(true)}
                  onMouseLeave={() => setShowContactTooltip(false)}
                  onClick={() => setShowContactListModal(true)}
                >
                  <FontAwesomeIcon icon={faUser} />
                  {showContactTooltip && (
                    <CustomTooltip
                      show={showContactTooltip}
                      position={{
                        x: 20,
                        y: 0,
                      }}
                    >
                      <label className="mx-4 text-sm text-gray-41">{t("contacts")}</label>
                    </CustomTooltip>
                  )}
                </div>

                <div
                  className="cursor-pointer p-1 bg-white hover:bg-gray-f2"
                  onMouseEnter={() => setShowDynamicContactTooltip(true)}
                  onMouseLeave={() => setShowDynamicContactTooltip(false)}
                  onClick={() => setShowDynamicContactModal(true)}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  {showDynamicContactTooltip && (
                    <CustomTooltip
                      show={showDynamicContactTooltip}
                      position={{
                        x: 20,
                        y: 0,
                      }}
                    >
                      <label className="mx-4 text-sm text-gray-41">{t("dynamic_contact_list")}</label>
                    </CustomTooltip>
                  )}
                </div>
              </div>
            </div>
            {showReceipentErr && (
              <label className="text-red-primary mt-1 text-sm font-semibold">{t("requried_field")}</label>
            )}
          </div>
        </section>

        <section className="flex flex-col bg-white mt-1 p-6">
          <div className="flex items-center">
            <label className="text-gray-41 flex-1">{t("fill_fields")}</label>
            <LanguageBox onChange={(lng) => setSelectedLanguage(lng)} selectedLanguage={selectedLanguage} />
          </div>
          <TextAreaInput
            title={t("message")}
            placeholder={`${t("message")} (${selectedLanguage.toUpperCase()})`}
            onChange={(e) => {
              setShowMessageErr(false);

              setMessage((prevState) => ({
                ...prevState,
                messageInfo: {
                  ...prevState.messageInfo,
                  textMessage: {
                    ...prevState.messageInfo.textMessage,
                    [selectedLanguage]: e.target.value,
                  },
                },
              }));
            }}
            value={
              message.messageInfo.textMessage && message.messageInfo.textMessage[selectedLanguage]
                ? message.messageInfo.textMessage[selectedLanguage]
                : ""
            }
            isRequired={true}
            hasFlag={true}
            showNumberOfChars={true}
          />
          {showMessageErr && (
            <label className="text-red-primary mt-1 text-sm font-semibold">{t("fill_all_message_languages")}</label>
          )}

          <div className="flex flex-col mt-4">
            <label className="text-sm">{t("note")}</label>
            <TextInput
              className="mt-2"
              value={message.messageInfo.note ?? ""}
              placeholder={""}
              hasError={false}
              errorMessage={""}
              onTextInputChanged={(value) =>
                setMessage((prevState) => ({
                  ...prevState,
                  messageInfo: {
                    ...prevState.messageInfo,
                    note: value,
                  },
                }))
              }
            />
          </div>
          <div
            className="mt-6 self-end"
            onBlur={() => {
              setTimeout(() => {
                setShowSendTooltip(false);
              }, 100);
            }}
          >
            <PrimaryButton
              onClick={() => {
                if (formIsValid()) {
                  setShowSendTooltip(true);
                }
              }}
              primaryOrNot
            >
              <div className="flex items-center">
                <label className="mr-4 cursor-pointer">{t("send")}</label>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </PrimaryButton>

            {showSendTooltip && (
              <CustomTooltip
                show={showSendTooltip}
                position={{
                  x: 20,
                  y: -10,
                }}
              >
                <div className="flex flex-col text-left text-sm">
                  <label
                    className="bg-white hover:bg-blue-e2 py-2 px-4 cursor-pointer"
                    onClick={() => sendSMS((response: Response) => history.goBack(), message, false)}
                  >
                    {t("send_now")}
                  </label>
                  <label
                    className="bg-white hover:bg-blue-e2 py-2 px-4 cursor-pointer"
                    onClick={() => setShowScheduleModal(true)}
                  >
                    {t("scheduled_send")}
                  </label>
                </div>
              </CustomTooltip>
            )}
          </div>
        </section>

        {showContactListModal && (
          <ContactListModal
            onClose={() => setShowContactListModal(false)}
            receipentArray={recipientArray}
            onSelectContacts={(selectedContacts) => setRecipientArray(selectedContacts)}
          />
        )}
        {showDynamicContactModal && (
          <DynamicContactModal
            onClose={() => setShowDynamicContactModal(false)}
            onSelectRange={(selectedRange) => setDynamicContactsRange((preValue) => preValue.concat(selectedRange))}
          />
        )}

        {showScheduleModal && (
          <ScheduleSendModal
            message={message}
            setMessage={setMessage}
            onClose={() => setShowScheduleModal(false)}
            onMessageCreated={(successfuly) => history.goBack()}
          />
        )}
      </main>
    </MainContainer>
  );
}

interface ReceipentViewProps {
  key: number;
  onRemoveReceipent: () => void;
  receipent?: RecipientObj;
  range?: DynamicContactWeekRange;
}

function ReceipentView(props: ReceipentViewProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      key={props.key}
      className="p-2 bg-gray-f6 border-[1px] border-gray-e8 mr-1 rounded-[3px] text-sm cursor-pointer"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {props.receipent
        ? props.receipent.name
        : props.range
        ? `${t("children")} ${props.range.fromWeek} ${t("to")} ${props.range.toWeek} ${t("weeks")}`
        : ""}
      <FontAwesomeIcon icon={faClose} className="ml-2 cursor-pointer" onClick={props.onRemoveReceipent} />

      {showTooltip && props.receipent && (
        <CustomTooltip
          show={showTooltip}
          position={{
            x: 20,
            y: 0,
          }}
        >
          {props.receipent.phone}
        </CustomTooltip>
      )}
    </div>
  );
}

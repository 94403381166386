import React, { KeyboardEventHandler, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { validationState, ERROR_INLINR_STYLE } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

const TextInput: React.FC<{
  value: string;
  placeholder: string;
  className?: string;
  dir?: string;
  inputClassName?: string;
  hasError: validationState | boolean;
  errorMessage: string;
  onTextInputChanged: (value: string) => void;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}> = (props) => {
  const { value, placeholder, className, dir, inputClassName, hasError, errorMessage, onTextInputChanged, onKeyPress } =
    props;
  const { t } = useTranslation();
  const [textInputClicked, setTextInputClicked] = useState(false);

  let applyErrorStyle = false;
  if (typeof hasError === "boolean") {
    applyErrorStyle = hasError;
  } else {
    applyErrorStyle = hasError === validationState.INVALID;
  }

  return (
    <div className={className} dir={dir}>
      <div className="relative" dir={errorMessage === t("number_valid") ? "ltr" : ""}>
        <input
          className={`input ${!textInputClicked && "bg-[#F6F6F6]"} ${inputClassName} ${
            applyErrorStyle && "placeholder:text-red-primary"
          }`}
          style={applyErrorStyle ? ERROR_INLINR_STYLE : {}}
          onChange={(e) => onTextInputChanged(e.target.value)}
          onClick={() => setTextInputClicked(true)}
          onBlur={() => setTextInputClicked(false)}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          onKeyPress={onKeyPress}
        />
        {applyErrorStyle && (
          <FontAwesomeIcon className="absolute top-[14.5px] right-4 text-red-primary" icon={faExclamationCircle} />
        )}
      </div>
      {applyErrorStyle && errorMessage && <p className="input__error-message ">{errorMessage}</p>}
    </div>
  );
};

export default TextInput;

import { useState } from "react";
import { MessageStatus, MessageStatusDetail, RepeatFrequency } from "../MsgModel";
import MsgStatusComponent, { getStatusBoxStyle } from "../MsgStatusComponent";
import CustomTooltip from "../../../UI/CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  receiversInfoStats: Array<MessageStatusDetail>;
  hasRepeat: boolean;
  repeatFrequncyType?: RepeatFrequency;
}

export default function MessageStatusCell(props: Props) {
  const [showRepeatTooltip, setShowRepeatTooltip] = useState(false);
  const repeatFrequency = `${props.repeatFrequncyType} Report`;

  // props.receiversInfoStats.sort((msgOne, msgTwo) => msgTwo.count - msgOne.count);
  // const componentArr: Array<React.ReactNode> = [];
  // props.receiversInfoStats
  //   .sort((itemOne, itemTwo) => itemOne.count - itemTwo.count) // TODO This sorting should be tested.
  //   .map((msgSta, index) => {
  //     componentArr.push(MsgStatusComponent({ msgStatus: msgSta.status, key: index.toString() }));
  //   });

  return (
    <ul className="flex items-center">
      <div className="flex items-center justify-center">
        {/* {props.msgStatus === MessageStatus.CD ||
        props.msgStatus === MessageStatus.DS ||
        props.msgStatus === MessageStatus.SD ||
        props.msgStatus === MessageStatus.NO ? (
          <MsgStatusComponent msgStatus={props.msgStatus} key={props.msgStatus} />
        ) : (
          <MsgStatusComponent msgStatus={props.receiversInfoStats[0].status} key={props.receiversInfoStats[0].status} />
        )} */}

        {/* TODO We should show the highest status here */}
        <MsgStatusComponent
          msgStatus={props.receiversInfoStats.length > 0 ? props.receiversInfoStats[0].status : MessageStatus.NN}
          key={props.receiversInfoStats.length.toString()}
        />

        {props.receiversInfoStats.length > 0 && (
          <MessageStatusContainer msgStatusComponentArr={props.receiversInfoStats} />
        )}
      </div>

      {props.hasRepeat && (
        <div
          className="flex cursor-pointer ml-1"
          onMouseEnter={() => setShowRepeatTooltip(true)}
          onMouseLeave={() => setShowRepeatTooltip(false)}
        >
          <FontAwesomeIcon className="text-gray-8d hover:text-blue-primary" icon={faRepeat} />

          <CustomTooltip
            show={showRepeatTooltip}
            position={{
              x: 50,
              y: -20,
            }}
          >
            {repeatFrequency}
          </CustomTooltip>
        </div>
      )}
    </ul>
  );
}

interface MessageStatusContainerProps {
  msgStatusComponentArr: Array<MessageStatusDetail>;
}

function MessageStatusContainer(props: MessageStatusContainerProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="flex w-8 h-8 items-center justify-center p-2 text-blue-primary bg-gray-e8 rounded-[3px] hover:text-white hover:bg-blue-secondary cursor-pointer"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <label className="cursor-pointer">{props.msgStatusComponentArr.length}</label>
      <CustomTooltip
        show={showTooltip}
        position={{
          x: 30,
          y: 10,
        }}
      >
        <div className="flex flex-col">
          {props.msgStatusComponentArr.map((msgSta, index) => (
            <div className="flex mt-2" key={index}>
              <label className={`mr-2 ${getStatusBoxStyle(msgSta.status)} bg-transparent`}>{msgSta.count}</label>
              <label className={`mr-2 ${getStatusBoxStyle(msgSta.status)} bg-transparent`}>{msgSta.status}</label>
            </div>
          ))}
        </div>
      </CustomTooltip>
    </div>
  );
}

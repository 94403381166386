import Modal from "../../UI/Modal";
import DatePicker from "../../UI/DatePicker";
import { useEffect, useState } from "react";
import { RepeatFrequency, MessageObj, getRepeatFrequencyByValue } from "./MsgModel";
import DateAndTimePicker from "../../UI/DateAndTimePicker";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import moment from "moment";
import NumberInput from "../../UI/NumberInput";
import ToggleSwitch from "../../UI/ToggleSwitch";
import PrimaryButton from "../../UI/PrimaryButton";
import MsgManagementAPI from "../../../store/api/MsgManagementAPI";
import { useTranslation } from "react-i18next";

interface Props {
  message: MessageObj;
  setMessage: React.Dispatch<React.SetStateAction<MessageObj>>;
  onMessageCreated: (successfuly: boolean) => void;
  onClose: () => void;
}

// const getTimezoneWithUTC = (tz: string) => {
//   const now = moment.tz(tz);

//   // Get the UTC offset in minutes and format it as "+/-HH:MM"
//   const offsetMinutes = now.utcOffset();
//   const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
//   const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

//   // Construct the UTC offset string (e.g., "-05:00" or "+02:00")
//   const sign = offsetMinutes >= 0 ? "+" : "-";
//   const formattedOffset = `UTC ${sign}${offsetHours.toString().padStart(2, "0")}:${offsetMinutesRemainder
//     .toString()
//     .padStart(2, "0")}`;

//   // Return the formatted timezone label
//   return `(${formattedOffset}): ${tz}`;
// };

const SCHEDULE_LIMIT_FOR_FUTURE = 10; // 10 minutes

export default function ScheduleSendModal(props: Props) {
  const { t } = useTranslation();

  const { sendSMS } = MsgManagementAPI();
  const message = props.message;
  const setMessage = props.setMessage;

  const getTodayTimeLimitation = () => {
    const maxTime = new Date();
    maxTime.setHours(23, 59, 59);

    const minTime = new Date();
    minTime.setHours(minTime.getHours(), minTime.getMinutes() + SCHEDULE_LIMIT_FOR_FUTURE);

    return { maxTime: maxTime, minTime: minTime };
  };

  // const prepareTimezones = () => {
  //   const result: Array<string> = [];
  //   Object.values(CimaTimezone).forEach((tz) => {
  //     result.push(getTimezoneWithUTC(tz));
  //   });

  //   return result;
  // };
  // const timezones = prepareTimezones();

  const [showDateError, setShowDateError] = useState(false);
  const [showEndDateError, setShowEndDateError] = useState(false);

  const [showEndTimeDatepicker, setShowEndTimeDatepicker] = useState(false);
  const [datepickerTimeLimitation, setDatepickerTimeLimitation] = useState<
    | {
        minTime?: Date;
        maxTime?: Date;
      }
    | undefined
  >(getTodayTimeLimitation());

  const getEnumValueByKey = (key: string) => {
    let result = RepeatFrequency.NO;
    Object.keys(RepeatFrequency).forEach((_key) => {
      if (_key === key) {
        result = RepeatFrequency[key as keyof typeof RepeatFrequency];
      }
    });

    return result;
  };

  const isToday = (targetDate: Date) => {
    const now = new Date();
    return (
      now.getFullYear() === targetDate.getFullYear() &&
      now.getMonth() === targetDate.getMonth() &&
      now.getDay() === targetDate.getDay()
    );
  };

  const allFieldsAreValid = () => {
    var allFieldsAreValid = true;

    if (!message.messageInfo.firstSendDate) {
      setShowDateError(true);
      allFieldsAreValid = false;
    }

    if (showEndTimeDatepicker && !message.messageInfo.endDate) {
      setShowEndDateError(true);
      allFieldsAreValid = false;
    }

    return allFieldsAreValid;
  };

  useEffect(() => {
    if (message.messageInfo.firstSendDate) {
      const messageTimeIsToday = isToday(message.messageInfo.firstSendDate);

      if (messageTimeIsToday) {
        setDatepickerTimeLimitation(getTodayTimeLimitation());
      } else {
        setDatepickerTimeLimitation(undefined);
      }
    } else {
      setDatepickerTimeLimitation(getTodayTimeLimitation());
    }
  }, [message.messageInfo.firstSendDate]);

  return (
    <Modal width={500}>
      <div className="flex flex-col p-6">
        <label className="text-lg">{t("scheduled_send_message")}</label>

        <div className="flex flex-col mt-4">
          <label className="text-sm">
            {t("first_send_date")} <span className="text-red-primary mx-1">*</span>
          </label>
          <div className="flex mt-1">
            <DateAndTimePicker
              widthClassName="w-full"
              selectedDate={message.messageInfo?.firstSendDate ?? null}
              onDateOrTimeChanged={(date) => {
                if (date) {
                  const now = new Date();
                  const selectedDateIsToday = isToday(date);

                  // By the way, if user select some invalid or past time, then we make the time valid by adding $SCHEDULE_LIMIT_FOR_FUTURE minutes to selected date.
                  const futureLimitInMilisecond = SCHEDULE_LIMIT_FOR_FUTURE * 60 * 1000;
                  if (selectedDateIsToday && date.getTime() - now.getTime() <= futureLimitInMilisecond) {
                    date.setHours(now.getHours(), now.getMinutes() + SCHEDULE_LIMIT_FOR_FUTURE);
                  }
                }

                setMessage((prevMessage) => ({
                  ...prevMessage,
                  messageInfo: {
                    ...prevMessage.messageInfo,
                    firstSendDate: date,
                    firstFormattedDate: moment(date).locale("en").format("YYYY-MM-DD HH:mm"),
                  },
                }));

                setShowDateError(false);
              }}
              placeHolder={t("select_data_and_time")}
              minDate={new Date()}
              maxTime={datepickerTimeLimitation?.maxTime}
              minTime={datepickerTimeLimitation?.minTime}
            />
          </div>
          {showDateError && (
            <label className="text-red-primary mt-1 text-sm font-semibold">{t("requried_field")}</label>
          )}
        </div>

        <div className="flex flex-col mt-4">
          <label className="text-sm">{t("repeat_frequency")}</label>
          <div className="mt-1">
            <Dropdown
              type={DropdownType.PRIMARY}
              widthClassName="w-full"
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setMessage((prevMessage) => ({
                  ...prevMessage,
                  messageInfo: {
                    ...prevMessage.messageInfo,
                    repeatFrequencyType: getRepeatFrequencyByValue(option),
                  },
                }));
              }}
              keys={Object.values(RepeatFrequency)}
              selectedKey={getEnumValueByKey(message.messageInfo.repeatFrequencyType)}
            />
          </div>
        </div>

        <div className="flex flex-col mt-4">
          <label className="text-sm">{t("repeat_every")}</label>
          <NumberInput
            className="placeholder:text-gray-d7 mt-1"
            value={message.messageInfo.repeatFrequency.toString()}
            placeholder=""
            onNumberInputChanged={(value) => {
              setMessage((prevMessage) => ({
                ...prevMessage,
                messageInfo: {
                  ...prevMessage.messageInfo,
                  repeatFrequency: Number(value),
                },
              }));
            }}
          />
        </div>

        <div className="flex mt-6">
          <label className="text-sm mr-6">{t("repeat_just_for_new_contacts")}</label>
          <ToggleSwitch
            checked={message.messageInfo.repeatForNewContactsOnly}
            onClick={() =>
              setMessage((prevMessage) => ({
                ...prevMessage,
                messageInfo: {
                  ...prevMessage.messageInfo,
                  repeatForNewContactsOnly: !prevMessage.messageInfo.repeatForNewContactsOnly,
                },
              }))
            }
          />
        </div>

        <div className="flex mt-6">
          <label className="text-sm mr-6">{t("never_end_time")}</label>
          <ToggleSwitch
            checked={showEndTimeDatepicker}
            onClick={() => {
              setMessage((prevMessage) => ({
                ...prevMessage,
                messageInfo: {
                  ...prevMessage.messageInfo,
                  endDate: undefined,
                  endFormattedDate: undefined,
                },
              }));
              setShowEndTimeDatepicker(!showEndTimeDatepicker);
            }}
          />
        </div>

        {showEndTimeDatepicker && (
          <div className="flex flex-col mt-6">
            <label className="text-sm">
              {t("end_time")} <span className="text-red-primary mx-1">*</span>
            </label>
            <div className="flex mt-1">
              <DatePicker
                onChange={(date) => {
                  setMessage((prevMessage) => ({
                    ...prevMessage,
                    messageInfo: {
                      ...prevMessage.messageInfo,
                      endDate: date ?? undefined,
                      endFormattedDate: moment(date).locale("en").format("YYYY-MM-DD"),
                    },
                  }));

                  setShowEndDateError(false);
                }}
                placeholderText={t("select_data_and_time")}
                selected={message.messageInfo.endDate ?? null}
              />
            </div>
            {showEndDateError && (
              <label className="text-red-primary mt-1 text-sm font-semibold">{t("requried_field")}</label>
            )}
          </div>
        )}

        <div className="flex items-center justify-end mt-8">
          <label className="link text-gray-41 mx-6" onClick={props.onClose}>
            {t("cancel")}
          </label>
          <PrimaryButton
            onClick={() => {
              if (allFieldsAreValid()) {
                sendSMS(
                  (response: Response) => {
                    props.onMessageCreated(response.status === 201);
                  },
                  message,
                  true
                );
              }
            }}
            primaryOrNot
            title={t("send")}
          />
        </div>
      </div>
    </Modal>
  );
}

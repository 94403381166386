import { MessageStatus } from "./MsgModel";
import { useTranslation } from "react-i18next";

interface Props {
  msgStatus: MessageStatus;
  key?: string;
}

export default function MsgStatusComponent(props: Props) {
  const { t } = useTranslation();

  return (
    <label key={props.key} className={`px-2 py-[6px] rounded-[3px] mr-1 ${getStatusBoxStyle(props.msgStatus)}`}>
      {t(props.msgStatus)}
    </label>
  );
}

export const getStatusBoxStyle = (msgStatus: MessageStatus) => {
  switch (msgStatus) {
    case MessageStatus.QD:
      return "bg-gray-e8 text-gray-41";
    case MessageStatus.SD:
      return "bg-purple-f7 text-purple-primary";
    case MessageStatus.SG:
      return "bg-orange-fe text-orange-primary";
    case MessageStatus.ST:
      return "bg-green-ec text-green-primary";
    case MessageStatus.DD:
      return "bg-blue-e2 text-blue-primary";
    case MessageStatus.FD:
      return "bg-red-f5 text-red-primary";
    case MessageStatus.UD:
    case MessageStatus.NO:
    case MessageStatus.NN:
    case MessageStatus.CD:
    case MessageStatus.DS:
      return "bg-gray-f2 text-gray-8d";
  }
};

import { t } from "i18next";
import Modal from "../../../UI/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/pro-light-svg-icons";
import NumberInput from "../../../UI/NumberInput";
import { useState } from "react";
import { DynamicContactWeekRange } from "./ContactModel";
import PrimaryButton from "../../../UI/PrimaryButton";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

interface Props {
  onSelectRange: (selectedRange: Array<DynamicContactWeekRange>) => void;
  onClose: () => void;
}

const DEFAULT_RANGE: DynamicContactWeekRange = {
  fromWeek: "0",
  toWeek: "0",
};

export default function DynamicContactModal(props: Props) {
  const { t } = useTranslation();
  const [ranges, setRanges] = useState<Array<DynamicContactWeekRange>>([DEFAULT_RANGE]);

  const addRange = () => {
    const currRanges = [...ranges];
    currRanges.push(DEFAULT_RANGE);

    setRanges(currRanges);
  };

  const updateRange = (targetIndex: number, newFrom: string, newTo: string) => {
    const currRanges = [...ranges];
    currRanges[targetIndex] = { fromWeek: newFrom, toWeek: newTo };

    setRanges(currRanges);
  };

  const removeRange = (targetIndex: number) => {
    const currRanges = [...ranges];
    currRanges.splice(targetIndex, 1);

    setRanges(currRanges);
  };

  return (
    <Modal width={1140}>
      <div className="flex flex-col p-6">
        <div className="flex items-center justify-between">
          <label className="text-black41 font-semibold">{t("dynamic_contact_list")}</label>
          <FontAwesomeIcon icon={faClose} className="cursor-pointer text-lg" onClick={props.onClose} />
        </div>
        <p className="mt-8 text-gray-41">{t("dynamic_list_desc")}</p>
        <label className="mt-8 text-gray-41">{t("age_range_week")}</label>
        {ranges.map((range, index) => {
          return (
            <div className="flex mt-2 items-center" key={index}>
              <NumberInput
                className="placeholder:text-gray-d7 w-[120px] mr-2"
                value={range.fromWeek}
                placeholder={t("from")}
                onNumberInputChanged={(value) => updateRange(index, value, range.toWeek)}
              />
              <NumberInput
                className="placeholder:text-gray-d7 w-[120px]"
                value={range.toWeek}
                placeholder={t("from")}
                onNumberInputChanged={(value) => updateRange(index, range.fromWeek, value)}
              />

              {index > 0 && (
                <FontAwesomeIcon
                  icon={faTrash}
                  className="ml-6 cursor-pointer hover:text-blue-primary"
                  onClick={() => removeRange(index)}
                />
              )}
            </div>
          );
        })}

        <label className="mt-4 text-blue-primary cursor-pointer" onClick={addRange}>
          <FontAwesomeIcon icon={faAdd} className="mr-1" />
          {t("add_new_age_range")}
        </label>

        <div className="flex items-center justify-end mt-8 mr-6">
          <label className="link text-gray-41 mx-6" onClick={props.onClose}>
            {t("cancel")}
          </label>
          <PrimaryButton
            onClick={() => {
              props.onSelectRange(ranges);
              props.onClose();
            }}
            primaryOrNot
            title={t("select")}
          />
        </div>
      </div>
    </Modal>
  );
}

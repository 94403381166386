import { useState } from "react";
import CustomTooltip from "../../../UI/CustomTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  sendTime: string;
  isStopped: boolean;
}

export default function SendTimeCell(props: Props) {
  const { t } = useTranslation();
  const [showStoppedTooltip, setShowStoppedTooltip] = useState(false);

  return (
    <div
      className="cursor-default"
      onMouseEnter={() => setShowStoppedTooltip(true)}
      onMouseLeave={() => setShowStoppedTooltip(false)}
    >
      <label className={props.isStopped ? "text-gray-b9 line-through" : ""}>{props.sendTime}</label>

      {props.isStopped && showStoppedTooltip && (
        <CustomTooltip
          show={showStoppedTooltip}
          position={{
            x: 30,
            y: 0,
          }}
        >
          {t("message_is_stopped_description")}
        </CustomTooltip>
      )}
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import IdValueType from "../../types/IdValueType";

export enum DropdownType {
  PRIMARY,
  SECONDARY,
}

export type OptionType = IdValueType;

const Dropdown: React.FC<{
  type: DropdownType;
  keys: OptionType[];
  selectedKey: OptionType[];
  onOptionSelected: (key: OptionType[]) => void;
  getValueByKey?: (key: string) => string;
  extraAvailableOptionsContainerClassName?: string;
  hasError?: boolean;
  errorMessage?: string;
}> = ({
  type,
  keys,
  selectedKey,
  onOptionSelected,
  getValueByKey,
  extraAvailableOptionsContainerClassName,
  hasError,
  errorMessage,
}) => {
  let rootElementclassName, availableOptionsContainerClassName;

  switch (type) {
    case DropdownType.PRIMARY:
      rootElementclassName = "w-[260px]";
      availableOptionsContainerClassName = "top-12";
      break;
    case DropdownType.SECONDARY:
      rootElementclassName = "w-[full]";
      availableOptionsContainerClassName = "top-12";
      break;
  }

  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (key: OptionType) => {
    if (key.name === "") {
      onOptionSelected([key]);
    } else {
      if (selectedKey.some((item) => item.name === "")) {
        selectedKey = selectedKey.filter((item) => item.name !== "");
      }

      if (selectedKey.find((item) => item.id === key.id)) {
        onOptionSelected(selectedKey.filter((selectedKey) => selectedKey.id !== key.id));
      } else {
        onOptionSelected([...selectedKey, key]);
      }
    }
  };

  return (
    <div className={`relative ${rootElementclassName}`} tabIndex={0} ref={dropdownRef}>
      <div
        className={`flex justify-between items-center pt-3 px-4 border border-gray-df rounded cursor-pointer hover:bg-white ${
          showMenu ? "border-blue-71 bg-white" : "bg-gray-f6"
        }`}
        onClick={() => setShowMenu((preShowOptions) => !preShowOptions)}
      >
        <div className="text-sm text-black41 h-8 overflow-y-hidden overflow-x-auto whitespace-nowrap mr-2">
          {selectedKey.length === 0
            ? getValueByKey
              ? getValueByKey("")
              : t("AL".toLowerCase())
            : selectedKey.map((key, index) => (
                <span key={key.id || index} className="inline-block">
                  {getValueByKey ? getValueByKey(key.name) : t(key.name.toLowerCase())}
                  {index < selectedKey.length - 1 && (
                    <span className={i18next.language === "ar" ? "ml-1" : "mr-1"}>,</span>
                  )}
                </span>
              ))}
        </div>
        <FontAwesomeIcon className="text-gray-b5 mb-2" icon={faAngleDown} />
      </div>
      {hasError && errorMessage && <p className="input__error-message ">{errorMessage}</p>}
      {showMenu && (
        <ul
          className={`absolute left-0 right-0 bg-white rounded shadow py-1 z-30 ${availableOptionsContainerClassName} ${extraAvailableOptionsContainerClassName}`}
        >
          {keys.map((key) => (
            <li
              key={key.id}
              className="p-2 text-sm text-black41 cursor-pointer hover:bg-gray-f6"
              onClick={() => {
                handleOptionClick(key);
              }}
            >
              <input
                type="checkbox"
                className="cursor-pointer mx-2"
                checked={selectedKey.some((item) => item.id.toString() === key.id.toString())}
                onChange={() => {}}
              />
              {getValueByKey ? getValueByKey(key.name) : t(key.name.toLowerCase())}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
